import React from "react"

/* eslint-disable */
const Terms = () => (
  <section className="section o-layout__item u-12/12">
    <h1 className="h1-heading text-4xl pt-4 pb-4">
      Shopsmart Mobile App and Browser End User Licence Agreement
    </h1>
    <small className="text-sm pb-4">Last Updated January 5th, 2018</small>
    <p className="pb-4 pt-4">
      This Mobile App End User Licence Agreement (the “Agreement”) is an
      irrevocable agreement between you (the “End User” or “You” and Shopsmart,
      an Australian Company (shopsmart.com.au).
    </p>
    <p className="pb-4 pt-4">
      This agreement regulates your use of this Mobile Device Application (The
      Application, all related documentation, the “App”) on any supported mobile
      platform and device (“Mobile Device).
    </p>
    <p className="pb-4 pt-4">
      INSTALLING AND USE OF THIS, YOU (A) ACKNOWLEDGE THAT YOU HAVE READ AND
      UNDERSTAND THIS AGREEMENT; (B) REPRESENT THAT YOU ARE OF LEGAL AGE TO
      ENTER INTO A BINDING AGREEMENT; AND (C) ACCEPT THIS AGREEMENT AND AGREE
      THAT YOU ARE LEGALLY BOUND BY ITS TERMS. IF YOU DO NOT AGREE TO THESE
      TERMS, DO NOT INSTALL OR USE THE APP.
    </p>
    <ol type="a" className="pt-4 pb-4">
      <li className="pb-4">
        <span className="terms-small-headings">Grant of Licence</span>
        Subject to the terms of this Agreement, Shopsmart offers you a limited,
        non-exclusive, and non-transferable licence to download, install, and
        use the Shopsmart App on a Mobile Device.
      </li>
      <li className="pb-4">
        <span className="terms-small-headings">Restrictions of Licence.</span>
        Licensor shall not copy the App (except as expressly permitted by this
        licence), modify, translate, adapt or otherwise create derivative works
        or improvements (whether or not patentable), of the App, reverse
        engineer, disassemble, decompile, decode, or otherwise attempt to derive
        or gain access to the source code of the App or any part thereof, or
        remove, delete, alter, or obscure any trademarks or any copyright,
        trademark, patent, or other intellectual property or proprietary rights
        notices from the App, including any copy thereof.
      </li>
      <li className="pb-4">
        <span className="terms-small-headings">Reservation of Rights.</span>
        You acknowledge and agree that the App is provided under licence, and
        not sold, to you. You do not acquire any ownership interest in the App
        under this Agreement, or any other rights thereto other than to use the
        App in accordance with the license granted, and subject to all terms,
        conditions and restrictions, under this Agreement. Shopsmart reserves
        and shall retain its entire right, title and interest in and to the App,
        including all copyrights, trademarks and other intellectual property
        rights therein or relating thereto, except as expressly granted to you
        in this Agreement.
      </li>
      <li className="pb-4">
        <span className="terms-small-headings">
          Collection and Use of Your Information.
        </span>
        You acknowledge that when you download, install or use the App,
        Shopsmart may use automatic means (including, for example, cookies) to
        collect information about your Mobile Device and about your use of the
        App. You may be required to provide certain information about yourself
        as a condition to downloading, installing or using the App or certain of
        its features or functionality. The information we collect through or in
        connection with this App is subject to our Privacy Policy, available at
        https://www.shopsmart.com.au/PrivacyPolicy, which is incorporated herein
        by reference. By downloading, installing, using, and providing
        information to or through the App, you consent to all actions taken by
        us with respect to your information in compliance with the Privacy
        Policy.
      </li>
      <li className="pb-4">
        <span className="terms-small-headings">Updates.</span>
        Shopsmart from time to time in its sole discretion may develop and
        provide App updates, which may include upgrades, bug fixes, patches, and
        other error corrections and/or new features (collectively, including
        related documentation, “Updates”). Updates also may modify or delete in
        their entirety certain features and functionality. You agree that
        Shopsmart.com.au, Shopsmart has no obligation to provide any Updates or
        to continue to provide or enable any particular features or
        functionality. Based on your Mobile Device settings, when your Mobile
        Device is connected to the internet, either the App will automatically
        download and install all available Updates or you may receive notice of
        or be prompted to download and install available Updates. You further
        agree that all Updates will be deemed part of the App and be subject to
        all terms and conditions of this Agreement.
      </li>
      <li className="pb-4">
        <span className="terms-small-headings">Term and Termination.</span>
        The term of this Agreement commences when you install the App and will
        continue in effect until terminated as set forth in this paragraph. You
        may terminate this Agreement by deleting the App and all copies thereof
        from your Mobile Device. This Agreement will terminate immediately and
        automatically without any notice if you violate any of the terms and
        conditions of this Agreement. Termination will not limit any of
        Shopsmart, Shopsmart.com.au’s rights or remedies at law or in equity.
      </li>
      <li className="pb-4">
        <span className="terms-small-headings">Disclaimer of Warranties.</span>
        THE APP IS PROVIDED TO YOU “AS IS,” WITH ALL FAULTS AND DEFECTS, AND
        WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED UNDER
        APPLICABLE LAW, SHOPSMART, ON ITS OWN BEHALF AND ON BEHALF OF ITS
        AFFILIATES AND ITS AND THEIR RESPECTIVE LICENSORS AND SERVICE PROVIDERS,
        EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY,
        OR OTHERWISE, WITH RESPECT TO THE APP, INCLUDING ALL IMPLIED WARRANTIES
        OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE,
        NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF
        DEALING, COURSE OF PERFORMANCE, USAGE, OR TRADE PRACTICE. WITHOUT
        LIMITATION TO THE FOREGOING, SHOPSMART PROVIDES NO WARRANTY OR
        UNDERTAKING, AND MAKES NO REPRESENTATION OF ANY KIND, THAT THE APP WILL
        MEET YOUR REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS, BE COMPATIBLE OR
        WORK WITH ANY OTHER SOFTWARE, APPLICATIONS, SYSTEMS, OR SERVICES,
        OPERATE WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY
        STANDARDS, OR BE ERROR FREE OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE
        CORRECTED. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR
        LIMITATIONS ON IMPLIED WARRANTIES OR THE LIMITATIONS ON THE APPLICABLE
        STATUTORY RIGHTS OF A CONSUMER, SO SOME OR ALL OF THE ABOVE EXCLUSIONS
        AND LIMITATIONS MAY NOT APPLY TO YOU.
      </li>
      <li className="pb-4">
        <span className="terms-small-headings">Limitation of Liability.</span>
        TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL
        SHOPSMART OR ITS AFFILIATES, OR ANY OF ITS OR THEIR RESPECTIVE LICENSORS
        OR SERVICE PROVIDERS, HAVE ANY LIABILITY ARISING FROM OR RELATED TO YOUR
        USE OF OR INABILITY TO USE THE APP OR THE CONTENT AND SERVICES. THE
        FOREGOING LIMITATIONS WILL APPLY WHETHER SUCH DAMAGES ARISE OUT OF
        BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE AND
        REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE OR SHOPSMART WAS
        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
      </li>
      <li className="pb-4">
        <span className="terms-small-headings">Indemnification.</span>
        You agree to indemnify, defend, and hold harmless Shopsmart, its
        affiliates, and their respective officers, directors, employees, and
        agents, and the heirs, successors, and assigns of the foregoing, from
        and against any and all losses, damages, liabilities, deficiencies,
        claims, actions, judgments, settlements, interest, awards, penalties,
        fines, costs, or expenses of whatever kind, including attorneys’ fees,
        arising from claims of third parties relating to your use of the App or
        your breach of this Agreement.
      </li>
      <li className="pb-4">
        <span className="terms-small-headings">
          Users of the Apple Platform.
        </span>
        If you download and use the iOS version of the App from the Apple App
        Store, you agree to the provisions in this paragraph. You acknowledge
        that this Agreement is entered into by and between SHOPSMART and you and
        - not with Apple, Inc. The foregoing notwithstanding, you acknowledge
        that Apple, Inc., and its subsidiaries are third-party beneficiaries of
        this Agreement and that Apple, Inc., has the right (and is deemed to
        have accepted the right) to enforce this Agreement. You acknowledge that
        Apple, Inc. has no obligation whatsoever to furnish any maintenance and
        support services with respect to the App. You acknowledge that you have
        reviewed the App Store Terms and Conditions (located online at
        http://www.apple.com/legal/itunes/us/terms.html#APPS). This Agreement
        incorporates by reference the Licensed App End User License Agreement
        (the “Apple Agreement”) published by Apple, Inc. (located online at
        http://www.apple.com/legal/itunes/appstore/dev/stdeula/). For purposes
        of this Agreement, the App is considered the “Licensed App” as defined
        in the Apple Agreement and Shopsmart is considered the “App Provider” as
        defined in the Apple Agreement. If any terms of this Agreement conflict
        with the terms of the Apple Agreement, the terms of this Agreement shall
        control unless otherwise required by the Apple Agreement. You further
        acknowledge and agree that in no event will Apple, Inc., be responsible
        for any claims relating to the App (including without limitation a
        third-party claim that the App infringes that third party’s intellectual
        property rights) or your use or possession of the App, including without
        limitation (i) product liability claims. (ii) any claim that the App
        fails to conform to any applicable legal or regulatory requirement, and
        (iii) claims arising under consumer protection or similar legislation.
      </li>
      <li className="pb-4">
        Users of the Google Play Store. If you download and use the Android
        version of the App from the Google Play Store, you agree to the
        provisions in this paragraph. You acknowledge that this Agreement is
        entered into by and between Shopsmart and you, not with Google, Inc.
        This Agreement incorporates by reference the Google Play Developer
        Distribution Agreement (the “Google Agreement”) published by Google,
        Inc. (located online at
        https://play.google.com/about/developer-distribution-agreement.html). If
        any terms of this Agreement conflict with the terms of the Google
        Agreement, the terms of this Agreement shall control unless otherwise
        required by the Google Agreement.
      </li>
      <li className="pb-4">
        <span className="terms-small-headings">Severability.</span>
        If any provision of this Agreement is illegal or unenforceable under
        applicable law, the remainder of the provision will be amended to
        achieve as closely as possible the effect of the original term and all
        other provisions of this Agreement will continue in full force and
        effect.
      </li>
      <li className="pb-4">
        <span className="terms-small-headings">Governing Law.</span>
        This Agreement is governed by and construed in accordance with the
        internal laws of the State of NSW, Australia, without giving effect to
        any choice or conflict of law provision or rule. Any legal suit, action
        or proceeding arising out of or related to this Agreement or the App
        shall be instituted exclusively in the federal courts of. You waive any
        and all objections to the exercise of jurisdiction over you by such
        courts and to venue in such courts.
      </li>
      <li className="pb-4">
        <span className="terms-small-headings">Waiver of Jury Trial.</span>
        ACH OF THE PARTIES HEREBY KNOWINGLY, VOLUNTARILY AND INTENTIONALLY
        WAIVES ANY RIGHT IT MAY HAVE TO A TRIAL BY JURY IN RESPECT OF ANY
        LITIGATION ARISING OUT OF, RELATING TO OR IN CONNECTION WITH THIS
        AGREEMENT. FURTHER, EACH PARTY CERTIFIES THAT NO REPRESENTATIVE OR AGENT
        OF EITHER PARTY HAS REPRESENTED, EXPRESSLY OR OTHERWISE, THAT SUCH PARTY
        WOULD NOT, IN THE EVENT OF SUCH LITIGATION, SEEK TO ENFORCE THIS WAIVER
        OF RIGHT TO JURY TRIAL PROVISION. Each of the parties acknowledges that
        this section is a material inducement for the other party to enter into
        this Agreement.
      </li>
      <li className="pb-4">
        <span className="terms-small-headings">
          Limitation of Time to File Claims.
        </span>
        ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO
        THIS AGREEMENT OR THE APP MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER
        THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS
        PERMANENTLY BARRED.
      </li>
      <li className="pb-4">
        <span className="terms-small-headings">Entire Agreement.</span>
        This Agreement and our Privacy Policy constitute the entire agreement
        between you and Shopsmart with respect to the App and supersede all
        prior or contemporaneous understandings and agreements, whether written
        or oral, with respect to the App.
      </li>
      <li className="pb-4">
        <span className="terms-small-headings">Waiver.</span>
        No failure to exercise, and no delay in exercising, on the part of
        either party, any right or any power hereunder shall operate as a waiver
        thereof, nor shall any single or partial exercise of any right or power
        hereunder preclude further exercise of that or any other right
        hereunder. In the event of a conflict between this Agreement and any
        applicable purchase or other terms, the terms of this Agreement shall
        govern.
      </li>
    </ol>
  </section>
)

export default Terms
/* eslint-enable */
