import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import SbEditable from "storyblok-react"
import DynamicComponent from "../components/DynamicComponent"
import useStoryblok from "../utils/storyblok"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Terms from "../components/agreement/Terms"

const AppAgreement = ({ data, location }) => {
  return (
    <Layout>
      <Seo title="App Agreement" pageName="app-agreement" />
      <Terms />
    </Layout>
  )
}

export default AppAgreement
